
async function getHtmlFiles() {
    try {
        const response = await fetch('/');
        const text = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, 'text/html');
        const links = doc.querySelectorAll('a');
        const htmlFiles = Array.from(links)
        .map(link => link.getAttribute('href'))
        .filter(href => href.endsWith('.html'));
        return htmlFiles;
    } catch (error) {
        console.error(error);
        return [];
    }
}

async function checkRoute() {
const validRoutes = await getHtmlFiles();
validRoutes.push('/');

const currentPath = window.location.pathname;

if (!validRoutes.includes(currentPath)) {
    window.location.href = '/404';
}
}

checkRoute();
  